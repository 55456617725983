import React from 'react'

function Title({ children, subtitle }) {
  return (
    <>
      <h1 className="title is-3">{children}</h1>
      {subtitle && <h4 className="subtitle is-4 is-italic">{subtitle}</h4>}
    </>
  )
}

export default Title
