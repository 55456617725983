import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'

export function News() {
  return (
    <StaticQuery
      query={graphql`
        query NewsQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "news-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  subtitle
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { edges } }) => (
        <aside className="menu">
          <p className="menu-label">Letzte Beiträge</p>
          <ul className="menu-list">
            {edges.map(
              ({
                node: {
                  id,
                  fields: { slug },
                  frontmatter: { title, subtitle },
                },
              }) => (
                <li key={id}>
                  <Link to={slug}>
                    <div>{title}</div>
                    {subtitle && <div className="help">{subtitle}</div>}
                  </Link>
                </li>
              )
            )}
          </ul>
        </aside>
      )}
    />
  )
}
