import { Link } from 'gatsby'
import React from 'react'
import Fade from '../../wal-common/src/components/Transitions/Fade/Fade'
import BackToTop from '../../wal-common/src/components/BackToTop/BackToTop'
import Header from '../../wal-common/src/components/Header/Header'
import { News } from '../News/News'
import SEO from '../seo'
import { SocialLinks } from '../SocialLinks/SocialLinks'
import Title from '../Title/Title'
import HeaderOben from './HeaderOben/HeaderOben'
import InBarOnSticky from './InBarOnSticky/InBarOnSticky'

const Layout = ({ children, cols, title, subtitle, noSection }) => {
  return (
    <>
      <SEO title={title}
           keywords={[`gatsby`, `application`, `react`]}/>

      <Header
        HeaderOben={HeaderOben}
        InBarOnSticky={InBarOnSticky}
      >
        <Link className="navbar-item" to="/ueber-mich">
          Über mich
        </Link>
        <Link className="navbar-item is-hidden-tablet" to="/news">
          News
        </Link>
        <Link className="navbar-item" to="/kundenerfahrungen">
          Kundenerfahrungen
        </Link>
        <Link className="navbar-item" to="/leistungen-und-preise">
          Leistungen und Preise
        </Link>
        <Link className="navbar-item" to="/fotoalbum">
          Fotoalbum
        </Link>
        <Link className="navbar-item" to="/anfahrt">
          Anfahrt
        </Link>
        <Link className="navbar-item" to="/termine">
          Termine
        </Link>
      </Header>

      {cols && (
        <div className="container is-clearfix">
          <div className="columns is-gapless">
            <div className="column is-three-quarters-tablet">
              <Fade>
                <section className="section">
                  <Title subtitle={subtitle}>{title}</Title>
                  {children}
                </section>
              </Fade>
            </div>
            <div className="column is-one-quarter is-hidden-mobile">
              <SocialLinks/>
              <News/>
            </div>
          </div>
        </div>
      )}

      {!cols && (
        <Fade>
          {noSection}
          <section className="section container ">{children}</section>
        </Fade>
      )}

      <footer className="footer">
        <SocialLinks />
        <p className="has-text-centered">
          &copy; Inna Chirkova 2016-{new Date().getFullYear()}{' '}
          <Link className="is-inverted"
                to="/impressum">
            Impressum
          </Link>
        </p>
      </footer>

      <BackToTop />
    </>
  )
}

export default Layout
