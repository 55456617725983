import { graphql } from 'gatsby'
import React from 'react'
import StadartPage from '../components/StandartPage/StandartPage'


const StandartPageTemplate = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <StadartPage
      title={post.frontmatter.title}
      subtitle={post.frontmatter.subtitle}
      image={post.frontmatter.image}
      html={post.htmlAst}
    />
  )
}

export default StandartPageTemplate

export const pageQuery = graphql`
  query StandartPageID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        image  
        title
      }
    }
  }
`
