import React from 'react'
import { Link } from 'gatsby'
import logoSrc from '../../../../static/media/logo_title.png'

import BEMHelper from 'react-bem-helper'

import './InBarOnSticky.scss'

const classes = new BEMHelper({
  name: 'InBarOnSticky',
})

function InBarOnSticky(props) {
  return (
    <Link to="/" className="InBarOnSticky navbar-item is-paddingless">
      <img
        {...classes('Logo')}
        alt="logo"
        src={logoSrc}
        style={{
          maxHeight: props.modifier ? 52 : 0,
        }}
      />
    </Link>
  )
}

export default InBarOnSticky
