import React from 'react'
import Title from '../../../../../components/Title/Title'

const TitleProxy = ({ children }) => {
  return (
    <Title>
      {children}
    </Title>
  )
}

export const titleProxyBuilder = (level) => ({children}) => (<Title level={level}>{children}</Title>)

export default TitleProxy
