import React from 'react'
import HTMLContent from '../../wal-common/src/components/HTMLContent/HTMLContent'
import ImageInPage from '../../wal-common/src/components/Image/ImageInPage/ImageInPage'
import Layout from '../Layout/Layout'

export default function StandartPage({ title, subtitle, image, html, content }) {
  return (
    <Layout title={title} subtitle={subtitle} cols={true}>
      {image && <ImageInPage file={image} />}

      <HTMLContent html={html}
                   content={content}/>
    </Layout>
  )
}
