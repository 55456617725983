import React from 'react'
import BEMHelper from 'react-bem-helper'
import './sticky.scss'

const classes = new BEMHelper({
  name: 'Sticky',
});

const sticky = (WrappedComponent, options = {}) => {
  class Sticky extends React.Component {
    state = {
      scroll: 0,
      height: 0,
    }

    stickyElRef = React.createRef();
    myRef = React.createRef();
    top = 0;

    handleScroll = () => {
      this.setState({ scroll: window.scrollY })
    }

    componentDidMount() {
      setTimeout(() => {
        const {top} = this.stickyElRef.current.getBoundingClientRect();
        this.top = top;

        const height = this.myRef.current.offsetHeight;
        this.setState({ height })

        console.log(top, height);
      }, 500)

      window.addEventListener('scroll', this.handleScroll)
      document.body.classList.add('StickyBody')
    }

    componentDidUpdate() {
      const { scroll, height } = this.state
      document.body.style.paddingTop = scroll > this.top ? `${height}px` : 0
    }

    render() {
      const { scroll } = this.state

      const isSticky = scroll > this.top

      return (
        <div
          ref={this.myRef}
          {...classes(null, {sticky: isSticky})}
        >
          <WrappedComponent
            stickyElRef={this.stickyElRef}
            modifier={isSticky && 'sticky'}
            {...this.props}
          />
        </div>
      )
    }
  }

  return Sticky;
  //
  //
  // return React.forwardRef((props, ref) => {
  //   return <Sticky {...props} stickyElRef={ref} />
  // })

}

export default sticky
