import React from 'react'
import { FaEnvelope, FaFacebook, FaVk } from 'react-icons/fa'
import IconButton from '../../wal-common/src/components/Icon/IconButton/IconButton'

export function SocialLinks() {
  return (
    <div className="m-b-md m-t-md has-text-centered">
      <span>
        <IconButton
          modifier="rotatedOnHover"
          url="https://www.facebook.com/mnushide-1100963436582697/"
          Icon={FaFacebook}
        />
      </span>
      <span className="m-l-md">
        <IconButton
          modifier="rotatedOnHover"
          url="https://vk.com/club75825591"
          Icon={FaVk}
        />
      </span>
      <span className="m-l-md">
        <IconButton
          modifier="rotatedOnHover"
          url="mailto:info@mnushi.de"
          Icon={FaEnvelope}
        />
      </span>
    </div>
  )
}
