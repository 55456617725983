import { Link } from 'gatsby'
import React from 'react'
import logoSrc from '../../../../static/media/logo_title.png'

import BEMHelper from 'react-bem-helper'

import './HeaderOben.scss'

const classes = new BEMHelper({
  name: 'HeaderOben',
})

function HeaderOben() {
  return (
    <div {...classes(null, null, 'is-clearfix has-text-right is-size-7')}>
      <Link to="/">
        <img
          {...classes('Logo', null, 'is-pulled-left')}
          alt=""
          src={logoSrc}
        />
      </Link>
      <div {...classes('Address', null, 'p-r-xs p-b-xs')}>
        Schildstiege 1a
        <br />
        48161 Münster
        <br />
        Tel: 02534 / 5890946
      </div>
    </div>
  )
}

export default HeaderOben
